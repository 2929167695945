.EmptyState {
  flex: 1 1 auto;
  height: 100%;

  &-content {
    margin-bottom: var(--lg); // Slight offset to shift content up a tad.
    max-width: 360px;
  }

  &-title {
    color: var(--color-text-default);
    font-size: 24px;
    font-weight: var(--font-weight-bold);
    margin: 0;
  }

  &-description {
    color: var(--gray40);
    font-size: 15px;
    font-weight: var(--font-weight-normal);
    line-height: 1.5;
    margin: 0;
    text-align: center;
  }
}
