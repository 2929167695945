@import '~@core/styles/vars';
@import '~@core/styles/vars.compat';
@import '~@core/styles/mixins/expand';
@import '~@core/styles/mixins/dark-mode';
@import '@core/styles/mixins/spinner';

.Tutorial {
  // Connect global Recipes variables to theme variables to support SuperHub live-previewing
  --recipe-button-color: var(--color-link-primary, --recipe-button-color);
  --recipe-button-color-hover: var(--color-link-primary-darken-5, --recipe-button-color-hover);
  --recipe-button-color-active: var(--color-link-primary-darken-10, --recipe-button-color-active);
  --recipe-button-color-focus: var(--color-link-primary-alpha-25, --recipe-button-color-active);
  --recipe-button-color-disabled: var(--color-link-primary-alpha-50, --recipe-button-color-active);

  @include dark-mode {
    --md-code-background: var(--gray15);
  }

  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &_loading{
    @include spinner;
  }

  // Styles applied when SuperHub is enabled.
  &_superhub {
    flex: 1;
    min-height: 0;
    width: 100%;
  }

  &-Wrapper {
    flex: 1 1 auto;
    width: 100%;
  }
}

.TutorialGrid {
  display: grid;
  gap: 15px 15px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--container);
  padding-bottom: 40px;
  padding-top: 20px;

  @media (max-width: 1180px) {
    gap: 20px 20px;
    grid-template-columns: 1fr 1fr;
    max-width: 775px;
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (max-width: 775px) {
    grid-template-columns: 1fr;
    max-width: 390px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.TutorialToolbar {
  $block: &;

  align-items: center;
  background: get('color' slate);
  border-radius: 9px;
  bottom: 45px;
  box-shadow:
    inset 0 1px 0 rgba(#fff, 0.1),
    0 5px 30px rgba(#000, 0.05);
  color: #fff;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: auto;
  padding: 10px;
  position: sticky;
  z-index: $z-toolbar; // overlap the inactive banner

  &-Button {
    align-items: center;
    background: none;
    border: 1px solid transparent;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-family: inherit;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    padding: 5px;
    position: relative;
    white-space: nowrap;

    + .TutorialToolbar-Button {
      margin-left: 10px;
    }

    &:hover,
    &:focus {
      background: rgba(#fff, 0.1);
    }

    &:active {
      background: rgba(#fff, 0.15);
    }

    &:focus {
      outline: none;
    }

    &:hover,
    &:focus,
    &:active {
      .TutorialToolbar-PopoverWrapper {
        pointer-events: auto;
      }

      .TutorialToolbar-Popover {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &-Icon {
      font-size: 25px;
      margin-bottom: 10px;
      opacity: 0.75;
    }

    &-Arrow {
      font-size: 10px;
      margin-left: 5px;
    }
  }

  &-PopoverWrapper {
    bottom: 100%;
    left: -10px;
    padding-bottom: 15px;
    pointer-events: none;
    position: absolute;
  }

  &-Popover {
    background: get('color' slate);
    border-radius: 6px;
    box-shadow:
      0 1px 2px rgba(#000, 0.05),
      0 5px 30px rgba(#000, 0.05);
    color: #fff;
    cursor: default;
    font-weight: 400;
    opacity: 0;
    padding: 20px;
    text-align: left;
    transform: translateY(10px);
    transition:
      opacity 0.15s cubic-bezier(0.16, 1, 0.3, 1),
      transform 0.15s cubic-bezier(0.16, 1, 0.3, 1);
    white-space: normal;
    width: 190px;

    &-Description {
      border-top: 1px solid rgba(#fff, 0.1);
      font-size: 13px;
      line-height: 1.2;
      margin-top: 10px;
      padding-top: 10px;

      &-Button {
        color: #fff;
        font-weight: 600;
        text-decoration: underline;

        &:hover,
        &:active,
        &:focus {
          color: #fff;
          text-decoration: none;
        }
      }
    }

    :global(.Toggle-display) {
      margin-left: 10px;
      order: 2;
    }

    :global(.Toggle-label) {
      color: var(--white);
      font-size: 14px;
      margin-left: 0;
    }
  }

  // Styles applied when SuperHub is enabled.
  &_superhub {
    backdrop-filter: blur(var(--md));
    background: rgba(var(--color-bg-page-rgb), 0.1);
    border: 0;
    border-radius: 0;
    border-top: 1px solid var(--color-border-default);
    bottom: 0;
    box-shadow: none;
    box-sizing: border-box;
    gap: var(--sm);
    height: 62px;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    padding-inline: var(--sm);
    width: 100%;

    #{$block} {
      &-Button {
        flex-direction: row;
        font-size: 12px;
        gap: 4px;

        &-Icon {
          font-size: 14px;
          margin: 0;
        }

        &-Arrow {
          display: none;
        }
      }
    }
  }
}

.TutorialConfirmation {
  text-align: center;

  &-Title {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  &-Description {
    line-height: 1.6;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.TutorialFooter {
  position: fixed;
  width: 100%;
}

// Styles applied when SuperHub is enabled.
.SuperHubModalWrapper {
  padding: 56px 30px 30px;
}
